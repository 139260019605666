import React from "react";
import Input from "../components/Input";
import Select from "../components/Select";
import { Instructions } from "../components";
import { css } from "emotion";
import { PRONOUNS } from "../constants/values";

const Client = ({
  onNext,
  saveInput,
  givenName,
  familyName,
  pronouns,
  pronounsOther,
  email,
  phoneNumber,
  onChange,
  errorMap,
}) => {
  return (
    <div>
      <div
        className={css`
          margin-top: 5px;
          background-color: #f2f2f2;
          padding-top: 10px;
          color: black;
          font-size: 1.3em;
          height: 100%;
        `}
      >
        <div>
          <Instructions>
            Please verify your client information below.
          </Instructions>
        </div>
        <div
          className={css`
            background-color: #f2f2f2;
            color: black;
            padding: 10px 15px;
          `}
        >
          <Input
            key={6}
            label={
              errorMap && errorMap["givenNameError"]
                ? `${errorMap["givenNameError"]}`
                : `Owner First Name`
            }
            value={givenName && givenName === "New Client" ? "" : givenName}
            onChange={(v) => onChange("givenName", v)}
            placeholder="Owner First Name"
            color={errorMap && errorMap["givenNameError"] ? "red" : ""}
          ></Input>
          <Input
            key={7}
            label={
              errorMap && errorMap["familyNameError"]
                ? `${errorMap["familyNameError"]}`
                : `Owner Last Name`
            }
            value={familyName ? familyName : ""}
            placeholder="Owner Last Name"
            onChange={(v) => onChange("familyName", v)}
            color={errorMap && errorMap["familyNameError"] ? "red" : ""}
          ></Input>
          <Select
            key={10}
            options={PRONOUNS}
            value={pronouns != null ? pronouns : ""}
            onChange={(v) => onChange("pronouns", v)}
            label={
              errorMap && errorMap["pronounsError"]
                ? `${errorMap["pronounsError"]}`
                : `Pronouns`
            }
            color={errorMap && errorMap["pronounsError"] ? "red" : ""}
          />
          {pronouns && pronouns === "OTHER" && (
            <Input
              key={11}
              label={
                errorMap && errorMap["pronounsOtherError"]
                  ? `${errorMap["pronounsOtherError"]}`
                  : `Pronouns (other)`
              }
              value={pronounsOther ? pronounsOther : ""}
              placeholder="Pronouns"
              onChange={(v) => onChange("pronounsOther", v)}
              color={errorMap && errorMap["pronounsOtherError"] ? "red" : ""}
            ></Input>
          )}
          <div>
            <Input
              key={9}
              label={
                errorMap && errorMap["emailError"]
                  ? `${errorMap["emailError"]}`
                  : `Email`
              }
              value={email ? email : ""}
              placeholder="Email"
              onChange={(v) => onChange("email", v)}
              className={css`
                color: red;
                font-size: 0.9em;
                padding-bottom: 15px;
              `}
              color={errorMap && errorMap["emailError"] ? "red" : ""}
            ></Input>
          </div>
          <Input
            key={8}
            phone
            placeholder="Preferred Phone Number"
            label={
              errorMap && errorMap["phoneError"]
                ? `${errorMap["phoneError"]}`
                : `Preferred phone number`
            }
            value={phoneNumber ? phoneNumber : ""}
            onChange={(v) => onChange("preferredPhoneNumber", v)}
            color={errorMap && errorMap["phoneError"] ? "red" : ""}
          ></Input>
        </div>
      </div>
    </div>
  );
};

export default Client;
