import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Label from "../components/Label";
import { css } from "emotion";
import Input from "./Input";

function toggle_visibility(id, toogle) {
  var e = document.getElementById(id);
  if (e.style.display === "block") e.style.display = "none";
  else e.style.display = "block";

  var hide_link = document.getElementById(toogle);
  if (hide_link.style.display === "none") hide_link.style.display = "block";
  else hide_link.style.display = "none";
}

const BirthDayPicker = ({
  // years,
  // months,
  // weeks,
  handleDateChange,
  onChange,
  value
}) => {
  const date = moment(value);

  const today = moment();
  const years = today.diff(date, "years");
  date.add(years, "years");

  const months = today.diff(date, "months");
  date.add(months, "months");

  const weeks = today.diff(date, "weeks");
  date.add(weeks, "weeks");

  const birthDate = moment()
    .subtract(years || 0, "years")
    .subtract(months || 0, "months")
    .subtract(weeks || 0, "weeks")
    .toISOString();

  return (
    <div
      className={css`
        /* font-size: 2em; */
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
      `}
    >
      <Label label={"How old is your pet?"} />
      <div
        className={css`
          display: grid;
          padding: 5px;
          grid-template-columns: 75px 75px 75px auto;
          > div:not(:last-child) {
            margin-right: 20px;
          }
        `}
      >
        <div>
          <Input
            // suffix="years"
            placeholder="1"
            label="Years (If less than 1 year, pls enter birthdate below)"
            value={years || undefined}
            type="number"
            onChange={n =>
              handleDateChange(
                moment(birthDate || undefined)
                  .add(years || 0, "years")
                  .subtract(n || 0, "years")
              )
            }
            width="70px"
          />
        </div>

        {/* Hiding the Months and Weeks because VEG does not want this for client checkin */}
        <div
          className={css`
            display: none;
          `}
        >
          <Input
            suffix="months"
            placeholder="0"
            value={months || undefined}
            type="number"
            onChange={n =>
              handleDateChange(
                moment(birthDate || undefined)
                  .add(months || 0, "months")
                  .subtract(n || 0, "months")
              )
            }
            width="70px"
          />
        </div>
        <div
          className={css`
            display: none;
          `}
        >
          >
          <Input
            suffix="weeks"
            placeholder="0"
            value={weeks || undefined}
            type="number"
            onChange={n =>
              handleDateChange(
                moment(birthDate || undefined)
                  .add(weeks || 0, "weeks")
                  .subtract(n || 0, "weeks")
              )
            }
            width="80px"
          />
        </div>
      </div>

      {!value && (
        <div id="toggle">
          <div
            className={css`
              font-size: 0.8em;
              text-decoration: underline;
            `}
          >
            <a onClick={() => toggle_visibility("selectDate", "toggle")}>
              select exact birth date
            </a>
          </div>
        </div>
      )}

      <div
        className={css`
          display: ${value ? "block" : "none"};
          margin-top: 0.2em;
        `}
        id="selectDate"
      >
        <div className={css``}>
          {" "}
          <Label label={"Birthdate"}></Label>
        </div>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          className={css`
            background-color: white;
            color: black;
            font-size: 0.7em;
            font-weight: 550;
          `}
        >
          <KeyboardDatePicker
            clearable
            value={value || ""}
            onChange={v => handleDateChange(v)}
            format="MM/DD/YYYY"
            maxDate={new Date()}
            className={css`
              background-color: white;
              color: black;
              font-size: 0.7em;
              font-weight: 550;
              .MuiInputBase-input {
                font: inherit;
                font-size: 0.7em;
                font-weight: 550;
                /* border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
                color: black; */
                width: 100%;
                height: 1.1875em;
                margin: 0;
                display: block;
                padding: 10px 0px 10px 10px;
                min-width: 0;
                background: none;
                box-sizing: content-box;
                -webkit-tap-highlight-color: transparent;
              }
            `}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default BirthDayPicker;
