import React from "react";
import { css } from "emotion";
import Input from "../components/Input";
import Select from "../components/Select";
import { STATES } from "../constants/values";

class ClientAddress extends React.Component {
  render() {
    const errorMap = this.props.errorMap;
    return (
      <div
        className={css`
          background-color: #f2f2f2;
          color: black;
          font-size: 1.3em;
          height: 100%;
          padding: 5px 15px;
        `}
      >
        <div
          className={css`
            background-color: #f2f2f2;
            color: black;
            input {
              font-weight: 550;
            }
          `}
        ></div>
        <div id="primaryAddress">
          <div>
            <Input
              key="address_1"
              value={this.props.client_primary_address.street_address}
              placeholder="Street Address"
              onChange={(v) => this.props.onChange("street_address", v)}
              label={
                errorMap && errorMap["streetAddressError"]
                  ? `${errorMap["streetAddressError"]}`
                  : `Address`
              }
              color={errorMap && errorMap["streetAddressError"] ? "red" : ""}
            ></Input>

            <Input
              name={"street_address2"}
              placeholder={"Apt"}
              value={this.props.client_primary_address.street_address2}
              onChange={(v) => {
                this.props.onChange("street_address2", v);
              }}
            />

            <Input
              name="city"
              value={this.props.client_primary_address.city}
              label={
                errorMap && errorMap["cityError"] && errorMap["cityError"]
                  ? `${errorMap["cityError"]}`
                  : `City`
              }
              placeholder={"City"}
              onChange={(e) => this.props.onChange("city", e)}
              color={errorMap && errorMap["cityError"] ? "red" : ""}
            />

            <Select
              options={STATES}
              value={this.props.client_primary_address.state}
              onChange={(v) => this.props.onChange("state", v)}
              label={
                errorMap && errorMap["stateError"]
                  ? `${errorMap["stateError"]}`
                  : `State`
              }
              color={errorMap && errorMap["stateError"] ? "red" : ""}
            />

            <Input
              name={"postal_code"}
              placeholder={"Zipcode"}
              value={this.props.client_primary_address.postal_code}
              onChange={(e) => this.props.onChange("postal_code", e)}
              color={errorMap && errorMap["postalCodeError"] ? "red" : ""}
              label={
                errorMap &&
                errorMap["postalCodeError"] &&
                errorMap["postalCodeError"]
                  ? `${errorMap["postalCodeError"]}`
                  : `Zip Code`
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ClientAddress;
