import React from "react";
import { Instructions } from "../components";
import { css } from "emotion";
import { PRONOUNS } from "../constants/values";
import { isDateOfBirthRequired } from "../utils/helpers";
import moment from "moment";

function formatPhoneNumber(s) {
  const s2 = ("" + s).replace(/\D/g, "");
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function formatPronouns(state) {
  return state.pronouns && state.pronouns === "OTHER"
    ? state.pronounsOther
    : PRONOUNS.find((p) => p.value === state.pronouns)?.label;
}

const Confirm = ({ onNext, state }) => {
  let referral = state.client_referral_options.find(
    (x) => x.value === Number(state.clientReferralSourceId)
  );

  let referralName = referral ? referral.label : "None";
  return (
    <div>
      <div
        className={css`
          margin-top: 5px;
          background-color: #f2f2f2;
          padding-top: 10px;
          color: black;
          font-size: 1.3em;
          height: 100%;
        `}
      >
        <div>
          <Instructions>Please confirm your information below.</Instructions>
        </div>
        <div
          className={css`
            background-color: #f2f2f2;
            color: black;
            padding: 10px 15px;
          `}
        >
          <div>
            {state.givenName} {state.familyName}
            {state.pronouns && state.pronouns !== "" && (
              <span
                className={css`
                  padding-left: 4px;
                `}
              >
                ({formatPronouns(state)})
              </span>
            )}
          </div>
          <div
            className={css`
              padding-right: 4px;
            `}
          >
            {state.client_primary_address.street_address}{" "}
            {state.client_primary_address.street_address2}{" "}
            {state.client_primary_address.city}{" "}
            {state.client_primary_address.state}{" "}
            {state.client_primary_address.postal_code}
          </div>
          {isDateOfBirthRequired(state) && (
            <div>
              Date of birth: {moment(state.dateOfBirth).format("MM-DD-YYYY")}
            </div>
          )}

          <div>{formatPhoneNumber(state.preferredPhoneNumber)}</div>
          <div>{state.email}</div>
          <div>Referral: {referralName}</div>
          <hr></hr>
          <div
            className={css`
              font-weight: 700;
            `}
          >
            Your Pet
          </div>
          <div>Name: {state.patientName}</div>
          <div>Species: {capitalize(state.species)}</div>
          <div>
            Breed: {capitalize(state.breed)}
            <div>Coat: {capitalize(state.color)}</div>
          </div>
          <div>
            {state.sex === "MALE" || state.sex === "FEMALE"
              ? state.sex
              : "Sex Unknown"}
            {" - "}
            {state.neutered && state.neutered === true
              ? "Neutered"
              : state.neutered && state.neutered === false
              ? "Not Neutered"
              : "Neutered Unknown"}{" "}
          </div>
          <div>Birthdate: {state.birthDate}</div>
          <div>Referring Vet:</div>
          {state.client_rdvms &&
            state.client_rdvms.length > 0 &&
            state.client_rdvms.map((d) => (
              <div key={d.id}>
                <div
                  className={css`
                    border: 1;

                    font-size: 1em;
                  `}
                >
                  <span>{d.name} </span>
                </div>
              </div>
            ))}
          {state.declineRdvm && <div>I don't have a primary vet.</div>}
          {state.isNewRdvmNeeded && <div>My primary vet was not listed.</div>}
          <hr></hr>
          <div
            className={css`
              font-weight: 700;
            `}
          >
            COVID-19
          </div>
          <div
            className={css`
              margin-bottom: 1em;
            `}
          >
            Have you experienced any symptoms of COVID-19, including a fever of
            100.4 degrees or higher, a new cough, new loss of
            taste/smell/shortness of breath, within the past 10 days?{" "}
            <strong>{state.covidQuestion1 ? "Yes" : "No"}</strong>
          </div>
          <div
            className={css`
              margin-bottom: 1em;
            `}
          >
            Have you gotten a positive result from COVID-19 test in the past 10
            days? <strong>{state.covidQuestion2 ? "Yes" : "No"}</strong>
          </div>
          <div>
            To the best of your knowledge, in the past 14 days, have you been in
            close contact (within 6ft for at least 10 min) with anyone who
            tested positive for COVID-19?{" "}
            <strong>{state.covidQuestion3 ? "Yes" : "No"}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
