import React, { Component } from "react";
import { css } from "emotion";
import InputSelect from "react-select";
import { Instructions } from "../components";
import Toggle from "react-toggle";
import "react-toggle/style.css";

class ClientRdvms extends Component {
  address(rdvm) {
    return rdvm
      ? [
          rdvm["addressLine1"],
          rdvm["addressLine2"],
          rdvm["city"],
          rdvm["state"],
        ].join(" ")
      : "";
  }

  loadOptions(inputValue, rdvms) {
    if (inputValue === "") return new Promise((resolve) => resolve([]));

    return rdvms.map((d) => ({
      value: d.id,
      label: d.name,
      address: this.address(d),
      ...d,
    }));
  }

  render() {
    const selectStyles = {
      menu: (styles) => ({
        ...styles,
        zIndex: 99,
        fontSize: ".7em",
      }),
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        fontSize: ".7em",
        marginBottom: "5px",
      }),
    };

    const rdvms = this.props.rdvms.map((d) => ({
      value: d.id,
      label: d.name,
      address: this.address(d),
      ...d,
    }));

    return (
      <div
        className={css`
          padding: 10px 15px;
        `}
      >
        <Instructions>
          Please verify or update your pet's regular veternarians below.
        </Instructions>

        {this.props.client_rdvms && this.props.client_rdvms.length > 0 && (
          <div
            className={css`
              border: none;
              text-align: center;
            `}
          >
            <span>Your Pet's Veterinarian(s)</span>
            <hr
              className={css`
                height: 2px;
                background-color: #ec173a;
                width: 75%;
              `}
            ></hr>
          </div>
        )}
        <div
          className={css`
            background-color: #f2f2f2;
            color: #000000;
          `}
        >
          {this.props.client_rdvms &&
            this.props.client_rdvms.length > 0 &&
            this.props.client_rdvms.map((d) => (
              <div
                className={css`
                  border: 1;
                  display: flex;
                  justify-content: space-between;
                  font-size: 1em;
                  width: 100%;
                  padding-bottom: 8px;
                `}
                key={d.id}
              >
                <div
                  className={css`
                    border: 1;

                    font-size: 1em;
                    width: 70%;
                  `}
                >
                  <span>{d.name} </span>
                  <br></br>
                  <span> {this.address(d)}</span>
                </div>
                <div
                  className={css`
                    width: 30%;
                    text-align: right;
                    button {
                      background: transparent;
                      border: none;
                      outline: none;
                      font: inherit;
                      background-color: red;
                      color: rgba(255, 255, 255, 0.9);
                      padding: 0.6em 30px;
                      width: 50px;
                      height: auto;
                      border-radius: 5px;
                      font-size: 0.8em;
                      position: relative;
                      text-align: center;
                      font-weight: 700;
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      box-shadow: 0 0 0 1px rgba(79, 178, 134, 0.1),
                        0 2px 5px 0 rgba(79, 178, 134, 0.08),
                        0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
                        0 1px 2px 0 rgba(0, 0, 0, 0.08);
                      transition: background-color 0.2s ease, opacity 0.2s ease,
                        color 0.2s ease, box-shadow 0.2s ease,
                        transform 0.2s ease;
                    }

                    button:hover {
                      box-shadow: 0 1px 1px 0 rgba(79, 178, 134, 0.45),
                        0 1px 3px 1px rgba(79, 178, 134, 0.45);
                      cursor: pointer;
                      color: #fff;
                    }
                  `}
                >
                  <button id={d.id} onClick={() => this.props.onRemove(d.id)}>
                    REMOVE
                  </button>
                </div>
                <br></br>
              </div>
            ))}
        </div>
        <div
          className={css`
            background-color: #f2f2f2;
            color: #000000;
          `}
        >
          <div>
            <InputSelect
              placeholder="Search veternarians to add..."
              options={rdvms}
              formatOptionLabel={(opt) => this.renderOption(opt)}
              onChange={this.props.onAdd}
              value={this.props.value}
              autoFocus={true}
              styles={selectStyles}
              field={this.props.field}
            />
          </div>
          <div
            className={css`
              display: flex;
              padding-bottom: 8px;
              justify-content: flex-end;

              button {
                background: transparent;
                border: none;
                outline: none;
                font: inherit;
                background-color: red;
                color: rgba(255, 255, 255, 0.9);
                padding: 0.6em 30px;
                width: 50px;
                height: auto;
                border-radius: 5px;
                font-size: 0.8em;
                position: relative;
                text-align: center;
                font-weight: 700;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 0 1px rgba(79, 178, 134, 0.1),
                  0 2px 5px 0 rgba(79, 178, 134, 0.08),
                  0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
                  0 1px 2px 0 rgba(0, 0, 0, 0.08);
                transition: background-color 0.2s ease, opacity 0.2s ease,
                  color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
              }

              button:hover {
                box-shadow: 0 1px 1px 0 rgba(79, 178, 134, 0.45),
                  0 1px 3px 1px rgba(79, 178, 134, 0.45);
                cursor: pointer;
                color: #fff;
              }
            `}
          ></div>
          {this.props.client_rdvms && this.props.client_rdvms.length === 0 && (
            <div
              className={css`
                margin-top: 0.5em;
              `}
            >
              <Toggle
                checked={this.props.declineRdvm}
                onChange={(e) =>
                  this.props.onChange("declineRdvm", e.target.checked)
                }
              />
              <label
                className={css`
                  padding-left: 15px;
                  margin-bottom: 10px;
                  vertical-align: top;
                `}
              >
                I don't have a primary vet.
              </label>

              <div
                className={css`
                  margin-top: 15px;
                `}
              >
                <Toggle
                  checked={this.props.isNewRdvmNeeded}
                  onChange={(e) =>
                    this.props.onChange("isNewRdvmNeeded", e.target.checked)
                  }
                />
                <label
                  className={css`
                    padding-left: 15px;
                    vertical-align: top;
                  `}
                >
                  My primary Vet is not listed above.
                </label>
              </div>
              <br></br>
              {this.props.errorMap && this.props.errorMap["rdvmsError"] && (
                <div
                  className={css`
                    color: red;
                    font-weight: 700;
                  `}
                >
                  {this.props.errorMap["rdvmsError"]}
                </div>
              )}
              <Instructions>
                **If you don't see your veternarian in the list above, we will
                collect this information upon your arrival.
              </Instructions>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderOption({ label, id, address }) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
        `}
      >
        <div>
          <div>{label}</div>
          <div
            className={css`
              font-weight: 600;
              font-size: 1em;
            `}
          >
            {address}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientRdvms;
