import React from "react";
import { css } from "emotion";

const Steps = ({ steps, currentStep, goBackTo }) => {
  const currentStepIdx = steps.findIndex(s => s.value === currentStep);

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: repeat(${steps.length}, 1fr);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 0.7em;
        background-color: red;
        color: #fff;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-width: 3px;
          border-bottom-style: solid;

          padding: 5px 5px;
        }
      `}
    >
      {steps.map((n, idx) => (
        <div
          key={idx}
          className={css`
            font-weight: ${idx <= currentStepIdx ? "700" : "600"};
            color: ${idx === currentStepIdx
              ? "#FFF"
              : idx < currentStepIdx
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.6)"};
            border-color: ${idx === currentStepIdx ? "#FFF" : "black"};
            transition: border-color 0.2s ease, color 0.2s ease;

            ${idx < currentStepIdx && {
              "&:hover": {
                cursor: "pointer"
              }
            }}
          `}
          onClick={() => {
            if (idx < currentStepIdx) goBackTo(n.value);
          }}
        >
          <div
            className={css`
              border-radius: 50%;
              background-color: ${idx === currentStepIdx
                ? "#FFF"
                : "rgba(0, 0, 0, 0.2)"};
              font-size: 0.85em;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: ${idx === currentStepIdx
                ? "#4956b9"
                : "rgba(255, 255, 255, 0.6)"};
              margin-right: 8px;
              font-weight: 600;
              transition: background-color 0.2s ease, color 0.2s ease;
            `}
          >
            {idx < currentStepIdx ? (
              <i
                className="material-icons"
                style={{
                  //   color: "rgb(73, 221, 177)",
                  color: "#fff",
                  //   color: "#f5f2bc",
                  display: "inline-block",
                  fontSize: "20px"
                }}
              >
                check_circle
              </i>
            ) : (
              idx + 1
            )}
          </div>
          <div>{n.label}</div>
        </div>
      ))}
    </div>
  );
};

export default Steps;
