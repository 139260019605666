import React from "react";
import { css } from "emotion";
import Input from "../components/Input";
import Label from "./Label";
import Select from "../components/Select";
import { MONTHS } from "../constants/values";
import moment from "moment";

class DateOfBirth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      day: "",
      month: "",
      year: "",
      errorMap: props.errorMap,
    };

    const dobMoment = moment(this.props.dateOfBirth, "YYYY-MM-DD", true);

    if (dobMoment.isValid()) {
      this.state = {
        day: dobMoment.format("DD"),
        month: dobMoment.format("MM"),
        year: dobMoment.year(),
      };
    }
  }

  updateDateOfBirth(part, value) {
    this.setState({ [part]: value }, () => {
      const dob =
        this.state.year +
        "-" +
        this.state.month +
        "-" +
        this.state.day.padStart(2, "0");
      const dobMoment = moment(dob, "YYYY-MM-DD", true);
      this.props.onChange(dob);

      if (dobMoment.isValid()) {
        this.setState({
          errorMap: { dayError: null, monthError: null, yearError: null },
        });
      } else {
        const fieldMapping = {
          "-1": "date",
          0: "year",
          1: "month",
          2: "day",
        };
        const target = fieldMapping[dobMoment.invalidAt()];
        this.setState({
          errorMap: {
            [target + "Error"]: target + " appears to be invalid, please check",
          },
        });
      }
    });
  }

  render() {
    const errorMap = this.state.errorMap;
    return (
      <div
        className={css`
          background-color: #f2f2f2;
          color: black;
          font-size: 1.3em;
          height: 100%;
          padding: 5px 15px;
        `}
      >
        <div
          className={css`
            background-color: #f2f2f2;
            color: black;
            input {
              font-weight: 550;
            }
          `}
        ></div>
        <div>
          <div
            className={css`
              font-size: 0.6em;
              margin-bottom: 20px;
            `}
          >
            <strong
              className={css`
                display: block;
              `}
            >
              Owner date of birth
            </strong>
            <span>Required due to controlled drug law</span>
          </div>
          {this.props.errorMap && this.props.errorMap["dateError"] && (
            <Label color="red" label={this.props.errorMap["dateError"]} />
          )}
          <Select
            key={13}
            options={MONTHS}
            value={this.state.month}
            onChange={(v) => this.updateDateOfBirth("month", v)}
            label={
              errorMap && errorMap["monthError"]
                ? `${errorMap["monthError"]}`
                : `Month`
            }
            color={errorMap && errorMap["monthError"] ? "red" : ""}
          />
          <Input
            key={14}
            label={
              errorMap && errorMap["dayError"]
                ? `${errorMap["dayError"]}`
                : `Day`
            }
            value={this.state.day}
            placeholder="Day"
            onChange={(v) => this.updateDateOfBirth("day", v)}
            color={errorMap && errorMap["dayError"] ? "red" : ""}
          ></Input>{" "}
          <Input
            key={15}
            label={
              errorMap && errorMap["yearError"]
                ? `${errorMap["yearError"]}`
                : `Year`
            }
            value={this.state.year}
            placeholder="Year"
            onChange={(v) => this.updateDateOfBirth("year", v)}
            color={errorMap && errorMap["yearError"] ? "red" : ""}
          ></Input>
        </div>
      </div>
    );
  }
}

export default DateOfBirth;
