import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ChatterApp from "./ChatterApp";
import { apiPost } from "./utils/api";
import queryString from "./deps/queryString";
import { css } from "emotion";
import LogoImage from "./images/VEG_StackedLogo_w.png";
import Header from "./containers/Header";

const paths = window.location.pathname.split("/");

var path_id = paths[1];

if (path_id === "d36$b3cqdwMtE@Bs2P6B") {
  ReactDOM.render(
    <ChatterApp token="d36$b3cqdwMtE@Bs2P6B" />,
    document.getElementById("root")
  );
} else if (path_id === "arrival") {
  const search = window.location.search;
  const initialState = queryString.parse(search.slice(1));

  apiPost(`arrival`, {
    appt: initialState.appt,
  })
    .then((r) => {
      ReactDOM.render(
        <div>
          <Header
            logoImage={LogoImage}
            address={
              r?.data?.location_address ? r.data.location_address : "N/A"
            }
            phoneNumber={
              r?.data?.location_phone_number
                ? r.data.location_phone_number
                : "N/A"
            }
          ></Header>
          <div
            className={css`
              justify-content: center;
              margin-top: 1em;
              font-size: 1em;
              font-weight: 700;
              padding: 10px;
            `}
          >
            <div>{r?.data?.message}</div>
            <br></br>
            <div>{r?.data?.opt_message}</div>
            {/* <div
              className={css`
                height: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
              `}
            >
              <iframe
                src={"https://vegdevsite.wpengine.com/"}
                style={{
                  // position: "absolute",
                  height: "800px",
                  width: "800px",
                }}
              />
            </div> */}
          </div>
        </div>,
        document.getElementById("root")
      );
    })
    .catch((error) => {
      console.log(error);
    });
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
