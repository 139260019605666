import React from "react";
import { css } from "emotion";

const Label = ({ label, align, width, color }) => (
  <div
    className={css`
      font-size: 0.6em;
      text-align: ${align ? align : "left"};
      margin-bottom: "10px";
    `}
  >
    <div
      className={css`
        text-align: ${align ? align : "left"};
        width: 300px;
        color: ${color ? color : "black"};
        :first-letter {
          text-transform: capitalize;
        }
      `}
    >
      {label}
    </div>
  </div>
);
export default Label;
