import React from "react";
import { css } from "emotion";
import Label from "./Label";

function getOffset(element) {
  let offset = { top: element.offsetTop, left: element.offsetLeft },
    parent = element.offsetParent;
  while (parent !== null && parent !== window) {
    offset.left += parent.offsetLeft;
    offset.top += parent.offsetTop;
    parent = parent.offsetParent;
  }
  return offset;
}

const Select = ({
  number,
  label,
  placeholder,
  description,
  horizontal,
  options,
  value,
  onChange,
  color,
}) => {
  const id = `q${number}`;
  const display = horizontal ? "flex" : "block";
  return (
    <div
      className={css`
        margin-bottom: 10px;
        display: ${display};
      `}
      id={id}
    >
      <Label color={color} label={label} description={description} />
      <div>
        <select
          className={css`
            font-size: 0.7em;
            cursor: pointer;
            background-color: white;

            width: 100%;
            max-width: 300px;

            padding: 10px;
            text-overflow: ellipsis;
            &:invalid {
              color: gray;
            }
          `}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          onFocus={() => {
            const el = document.getElementById(id);

            const elOffset = getOffset(el).top;
            const elHeight = el.clientHeight;
            const windowHeight = window.innerHeight;
            let offset;

            if (elHeight < windowHeight) {
              offset = elOffset - (windowHeight / 2 - elHeight / 2);
            } else {
              offset = elOffset;
            }

            window.scrollTo({
              top: offset,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <option default>{placeholder}</option>
          {options &&
            options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
