import React from "react";
import { css } from "emotion";

const Header = ({ logoImage, address, phoneNumber }) => {
  const addressLink =
    "http://maps.google.com/?q=Veterinary%20Emergency%20Group%20" +
    encodeURIComponent(address);

  const phoneLink = phoneNumber
    ? "tel:+1" + phoneNumber.replace(/\D/g, "")
    : "";
  return (
    <div
      className={css`
        display: flex;
        justify-content: space-between;

        background-color: black;
        padding: 10px 5px;
        img {
          max-height: 55px;
        }
      `}
    >
      <div
        className={css`
          display: flex;
        `}
      >
        <img src={logoImage} alt="logo" />
      </div>

      {address && (
        <div
          className={css`
            margin-left: 20px;
            font-size: 0.9em;
            width: 50%;
            i {
              color: red;
            }
            a:link {
              color: white;
            }
            a:visited {
              color: white;
            }
            a:hover {
              color: red;
            }
            a:focus {
              color: red;
            }
            a:active {
              color: white;
            }
            a:link:hover {
              color: red;
            }
          `}
        >
          <div
            className={css`
              /* margin-left: 20px; */
              display: flex;
              justify-content: flex-start;
            `}
          >
            <i className="fa fa-map-marker"></i>&nbsp;&nbsp;
            {address && (
              <a
                title="Get directions"
                href={addressLink}
                target="_blank"
                rel="noopener noreferrer"
                data-wahfont="15"
              >
                {" "}
                {address}
              </a>
            )}
          </div>

          <div
            className={css`
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
            `}
          >
            <i className="fa fa-phone"></i> &nbsp;&nbsp;
            {phoneNumber && <a href={phoneLink}>{phoneNumber}</a>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
