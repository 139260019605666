import React, { Component } from "react";
import { css } from "emotion";
import { apiGet, apiPost } from "./utils/api";
import Select from "./components/Select";
import LogoImage2 from "./images/VEG_StackedLogo_w.png";
import Header from "./containers/Header";
import Input from "./components/Input";

class ChatterApp extends Component {
  state = {};

  getLink() {
    apiPost(`${this.props.token}/get_checkin_link`, {
      input: {
        locationId: this.state.selectedLocation,
        phoneNumber: this.state.phoneNumber.replace(/\D/g, "")
      }
    }).then(r => {
      if (r.data["Appointment Update"] === "success")
        alert("Sent Client Self Check-in");
      else alert("Failed to send client checkin");
      this.setState({
        phoneNumber: ""
      });
    });
  }

  componentDidMount() {
    apiGet(`${this.props.token}/get_locations?org=38`)
      .then(({ data, loading }) => {
        if (!data && loading) return;

        var defaultLocation = data.locations.find(loc => loc.value === 115);
        var locations = data.locations;
        this.setState({
          locations: locations,
          loading: true,
          selectedLocation: defaultLocation ? defaultLocation.value : ""
        });
      })
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { loading } = this.state;
    if (!loading) return "";

    return (
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 50px;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 50vh;
            width: 50vw;
          `}
        >
          <div
            className={css`
              color: black;
              background-color: #f2f2f2;
              width: 100vw;
              margin-bottom: 100px;
            `}
          >
            <Header logoImage={LogoImage2}></Header>

            <br></br>
            <br></br>
            <br></br>
            <div
              className={css`
                font-size: 25px;
                button {
                  background: transparent;
                  border: none;
                  outline: none;
                  font: inherit;
                  background-color: red;
                  color: rgba(255, 255, 255, 0.9);
                  padding: 0.4em 20px;
                  width: 140px;
                  height: auto;
                  border-radius: 5px;
                  font-size: 0.8em;
                  position: relative;
                  text-align: center;
                  font-weight: 700;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 0 0 0 1px rgba(79, 178, 134, 0.1),
                    0 2px 5px 0 rgba(79, 178, 134, 0.08),
                    0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
                    0 1px 2px 0 rgba(0, 0, 0, 0.08);
                  transition: background-color 0.2s ease, opacity 0.2s ease,
                    color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
                }

                button:hover {
                  box-shadow: 0 1px 1px 0 rgba(79, 178, 134, 0.45),
                    0 1px 3px 1px rgba(79, 178, 134, 0.45);
                  cursor: pointer;
                  color: #fff;
                }
                select {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  border: 1px solid #ccc;
                  border-radius: 3px;
                  padding: 10px 10px;
                  margin-top: 10px;
                }
                input {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  border: 1px solid #ccc;
                  border-radius: 3px;
                  padding: 10px 10px;
                  margin-top: 10px;
                }

                font-size: 1em;
              `}
            >
              <div
                className={css`
                  font-size: 0.8em;
                  text-align: "left";
                  margin-bottom: "10px";
                `}
              >
                <div
                  className={css`
                    text-align: "left";
                    width: 300px;
                  `}
                >
                  Select the location for the client.
                </div>{" "}
              </div>

              <Select
                options={this.state.locations}
                value={
                  this.state.selectedLocation != null
                    ? this.state.selectedLocation
                    : ""
                }
                onChange={value => {
                  this.setState({ selectedLocation: value });
                }}
                clearable={false}
                placeholder="Select Location for client..."
              />
              <br></br>

              <Input
                key={8}
                phone
                placeholder="Client's Mobile Phone Number"
                value={
                  this.state.phoneNumber != null ? this.state.phoneNumber : ""
                }
                onChange={value => {
                  this.setState({ phoneNumber: value });
                }}
              ></Input>
              <button onClick={() => this.getLink()}>Send Client Link</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatterApp;
