import React, { useState } from "react";
import Input from "../components/Input";
import { NextButton, Instructions, Options } from "../components";
import Label from "../components/Label";
import { css } from "emotion";
import moment from "moment";
import Select from "../components/Select";
import BirthDayPicker from "../components/BirthDayPicker";
import {
  DOG_BREEDS,
  CAT_BREEDS,
  SPECIES,
  FELINE_COLORS,
  CANINE_COLORS,
  AVIAN_COLORS,
  SEX_OPTIONS,
  CONFIRM_OPTIONS,
} from "../constants/values";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const Patient = ({
  onNext,
  patientName,
  sex,
  species,
  breed,
  color,
  birthDate,
  neutered,
  onChange,
  errorMap,
}) => {
  const date = moment(birthDate);

  const today = moment();
  const years = today.diff(date, "years");
  date.add(years, "years");

  const months = today.diff(date, "months");
  date.add(months, "months");

  const weeks = today.diff(date, "weeks");
  date.add(weeks, "weeks");

  // const selectStyles = {
  //   menu: (styles) => ({
  //     ...styles,
  //     zIndex: 99,
  //     fontSize: ".7em",
  //   }),
  //   control: (styles) => ({
  //     ...styles,
  //     backgroundColor: "white",
  //     fontSize: ".7em",
  //     marginBottom: "5px",
  //   }),
  // };

  onChange = onChange.bind(this);

  return (
    <div>
      <div>
        <div
          className={css`
            margin-top: 5px;
            background-color: #f2f2f2;
            color: black;
            font-size: 1.3em;
            height: 100%;
          `}
        >
          <div>
            <Instructions>
              Please verify your Pet's information below.
            </Instructions>
          </div>
          <div
            className={css`
              background-color: #f2f2f2;
              color: black;
              padding: 10px 15px;
            `}
          >
            <form>
              <Input
                key={0}
                placeholder="Pet's Name"
                value={patientName ? patientName : ""}
                onChange={(v) => onChange("patientName", v)}
                label={
                  errorMap && errorMap["patientNameError"]
                    ? `${errorMap["patientNameError"]}`
                    : `Pet's Name`
                }
                autoFocus={true}
                color={errorMap && errorMap["patientNameError"] ? "red" : ""}
              ></Input>
              <Options
                key={4}
                value={sex ? sex.toUpperCase() : ""}
                onChange={(v) => onChange("sex", v)}
                options={SEX_OPTIONS}
              />

              <Select
                key={1}
                options={SPECIES}
                value={species != null ? species : ""}
                onChange={(v) => onChange("species", v)}
                clearable={false}
                label={
                  errorMap && errorMap["speciesError"]
                    ? `${errorMap["speciesError"]}`
                    : ``
                }
                placeholder="Select Species"
                color={errorMap && errorMap["speciesError"] ? "red" : ""}
              />

              {(species === "canine" || species === "feline") && (
                <div>
                  <Select
                    key={2}
                    value={breed != null ? breed : ""}
                    options={
                      species === "canine"
                        ? DOG_BREEDS
                        : species === "feline"
                        ? CAT_BREEDS
                        : []
                    }
                    onChange={(v) => onChange("breed", v)}
                    placeholder="Select Breed"
                  />
                </div>
              )}
              {(species === "canine" ||
                species === "feline" ||
                species === "avian") && (
                <div>
                  <Select
                    key={3}
                    value={color != null ? color : ""}
                    options={
                      species === "canine"
                        ? CANINE_COLORS
                        : species === "feline"
                        ? FELINE_COLORS
                        : species === "avian"
                        ? AVIAN_COLORS
                        : []
                    }
                    onChange={(v) => onChange("color", v)}
                    creatable="true"
                    placeholder="Select Coat color"
                  />
                </div>
              )}
              <div
                className={css`
                  padding-top: 15px;
                `}
              >
                <Options
                  key={5}
                  label="Is your Pet Neutered/Spayed?"
                  value={neutered}
                  options={CONFIRM_OPTIONS.concat([
                    { label: "Unknown", value: null },
                  ])}
                  onChange={(v) => onChange("neutered", v)}
                />
                <div>
                  <Label>How old is your pet?</Label>
                  <BirthDayPicker
                    handleDateChange={(v) => {
                      if (v) onChange("birthDate", v.format("MM-DD-YYYY"));
                    }}
                    value={
                      birthDate
                        ? moment(birthDate, [
                            "MM-DD-YYYY",
                            "MM-D-YYYY",
                            "M-DD-YYYY",
                            "M-D-YYYY",
                          ])
                        : undefined
                    }
                  ></BirthDayPicker>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Patient;
