import React from "react";
import { css } from "emotion";
import Label from "./Label";
import MaskedInput from "react-text-mask";

const PHONE_MASK = [
  "(",
  /\d/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const Input = ({
  number,
  type,
  suffix,
  label,
  placeholder,
  description,
  active,
  phone,
  value,
  onChange,
  width,
  color
}) => {
  const id = `q${number}`;

  return (
    <div
      className={css`
        margin-bottom: 10px;
        width: ${width ? width : "auto"};
      `}
      id={id}
    >
      <Label color={color} label={label} description={description} />
      <div>
        {phone && (
          <MaskedInput
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            mask={!value || value.length <= 10 ? PHONE_MASK : false}
            guide={false}
            className={css`
              background-color: white;
              color: black;
              font-weight: 550;
            `}
          />
        )}
        {!phone && (
          <input
            type={type || "text"}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            className={css`
              color: black;
              font-weight: 550;
            `}
          />
        )}

        {suffix && (
          <div
            className={css`
              font-weight: 300;
              font-size: 0.8em;
              height: 100%;
              display: flex;
              align-items: center;
            `}
          >
            {suffix}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
