export const SPECIES = [
  { value: "canine", label: "Canine" },
  { value: "feline", label: "Feline" },
  { value: "equine", label: "Equine" },
  { value: "avian", label: "Avian" },
  { value: "porcine", label: "Porcine" },
  { value: "vulpine", label: "Vulpine" },
  { value: "lagomorph", label: "Lagomorph" },
  { value: "reptillian", label: "Reptillian" },
  { value: "exotic", label: "Exotic" },
  { value: "small animal", label: "Small Animal" },
  { value: "other", label: "Other" },
];

export const MONTHS = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const CONFIRM_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const SEX_OPTIONS = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "UNKNOWN", label: "Unknown" },
];

export const PRONOUNS = [
  { value: "SHE", label: "She/Her/Hers" },
  { value: "HE", label: "He/Him/His" },
  { value: "THEY", label: "They/Them/Theirs" },
  { value: "ZE", label: "Ze/Hir/Hirs" },
  { value: "OTHER", label: "Other" },
];

export const DOG_BREEDS = [
  "Affenpinscher",
  "Afghan Hound",
  "Aidi",
  "Airedale Terrier",
  "Akbash Dog",
  "Akita",
  "Alano Español",
  "Alaskan Klee Kai",
  "Alaskan Malamute",
  "Alpine Dachsbracke",
  "Alpine Spaniel",
  "American Bulldog",
  "American Cocker Spaniel",
  "American Eskimo Dog",
  "American Foxhound",
  "American Hairless Terrier",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "American Water Spaniel",
  "Anglo-Français de Petite Vénerie",
  "Appenzeller Sennenhund",
  "Ariege Pointer",
  "Ariegeois",
  "Armant",
  "Armenian Gampr dog",
  "Artois Hound",
  "Australian Cattle Dog",
  "Australian Kelpie",
  "Australian Shepherd",
  "Australian Silky Terrier",
  "Australian Stumpy Tail Cattle Dog",
  "Australian Terrier",
  "Azawakh",
  "Bakharwal Dog",
  "Bagle (Basset Hound/Beagle)",
  "Barbet",
  "Basenji",
  "Basque Shepherd Dog",
  "Basset Artésien Normand",
  "Basset Bleu de Gascogne",
  "Basset Fauve de Bretagne",
  "Basset Hound",
  "Bavarian Mountain Hound",
  "Beagle",
  "Beagle-Harrier",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Shepherd Dog (Groenendael)",
  "Belgian Shepherd Dog (Laekenois)",
  "Belgian Shepherd Dog (Malinois)",
  "Bergamasco Shepherd",
  "Berger Blanc Suisse",
  "Berger Picard",
  "Berner Laufhund",
  "Bernese Mountain Dog",
  "Bernedoodle",
  "Bichon Frise",
  "Billy",
  "Black and Tan Coonhound",
  "Black and Tan Virginia Foxhound",
  "Black Norwegian Elkhound",
  "Black Russian Terrier",
  "Bloodhound",
  "Blue Lacy",
  "Blue Paul Terrier",
  "Boerboel",
  "Bohemian Shepherd",
  "Bolognese",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Ardennes",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Bracco Italiano",
  "Braque d'Auvergne",
  "Braque du Bourbonnais",
  "Braque du Puy",
  "Braque Francais",
  "Braque Saint-Germain",
  "Brazilian Terrier",
  "Briard",
  "Briquet Griffon Vendéen",
  "Brittany",
  "Broholmer",
  "Bruno Jura Hound",
  "Bucovina Shepherd Dog",
  "Bugg (Pug/Boston Terrier)",
  "Bull and Terrier",
  "Bull Terrier (Miniature)",
  "Bull Terrier",
  "Bulldog",
  "Bullenbeisser",
  "Bullmastiff",
  "Bully Kutta",
  "Burgos Pointer",
  "Cairn Terrier",
  "Canaan Dog",
  "Canadian Eskimo Dog",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Carolina Dog",
  "Carpathian Shepherd Dog",
  "Catahoula Cur",
  "Catalan Sheepdog",
  "Caucasian Shepherd Dog",
  "Cavachon (Cavalier King Charles Spaniel/Bichon Frise)",
  "Cavalier King Charles Spaniel",
  "Central Asian Shepherd Dog",
  "Cesky Fousek",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Chessadoodle (Chesapeake Bay Retiever/Poodle)",
  "Chien Français Blanc et Noir",
  "Chien Français Blanc et Orange",
  "Chien Français Tricolore",
  "Chien-gris",
  "Chihuahua",
  "Chilean Fox Terrier",
  "Chinese Chongqing Dog",
  "Chinese Crested Dog",
  "Chinese Imperial Dog",
  "Chinook",
  "Chipin (Chihuahua/MinPin)",
  "Chippiparai",
  "Chiweenie (Chihuahua/Dachshund)",
  "Chorkie (Chihuahua/Yorkshire Terrier Mix)",
  "Chow Chow",
  "Cierny Sery",
  "Cimarrón Uruguayo",
  "Cirneco dell'Etna",
  "Clumber Spaniel",
  "Combai",
  "Cockapoo (Cocker Spaniel/Poodle)",
  "Cordoba Fighting Dog",
  "Coton de Tulear",
  "Cretan Hound",
  "Croatian Sheepdog",
  "Cumberland Sheepdog",
  "Curly Coated Retriever",
  "Cursinu",
  "Cão da Serra de Aires",
  "Cão de Castro Laboreiro",
  "Cão Fila de São Miguel",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Danish Swedish Farmdog",
  "Deutsche Bracke",
  "Doberman Pinscher",
  "Dorkie (Dachshund/Yorkie)",
  "Dogo Argentino",
  "Dogo Cubano",
  "Dogue de Bordeaux",
  "Drentse Patrijshond",
  "Drever",
  "Dunker",
  "Dutch Shepherd Dog",
  "Dutch Smoushond",
  "East Siberian Laika",
  "East-European Shepherd",
  "Elo",
  "English Bulldog",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Mastiff",
  "English Setter",
  "English Shepherd",
  "English Springer Spaniel",
  "English Toy Terrier (Black &amp; Tan)",
  "English Water Spaniel",
  "English White Terrier",
  "Entlebucher Mountain Dog",
  "Estonian Hound",
  "Estrela Mountain Dog",
  "Eurasier",
  "Field Spaniel",
  "Fila Brasileiro",
  "Finnish Hound",
  "Finnish Lapphund",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "Formosan Mountain Dog",
  "Fox Terrier (Smooth)",
  "French Bulldog",
  "French Spaniel",
  "Galgo Español",
  "Gascon Saintongeois",
  "German Longhaired Pointer",
  "German Pinscher",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Spaniel",
  "German Spitz",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Goldendoodle",
  "Gordon Setter",
  "Gran Mastín de Borínquen",
  "Grand Anglo-Français Blanc et Noir",
  "Grand Anglo-Français Blanc et Orange",
  "Grand Anglo-Français Tricolore",
  "Grand Basset Griffon Vendéen",
  "Grand Bleu de Gascogne",
  "Grand Griffon Vendéen",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greek Harehound",
  "Greenland Dog",
  "Greyhound",
  "Griffon Bleu de Gascogne",
  "Griffon Bruxellois",
  "Griffon Fauve de Bretagne",
  "Griffon Nivernais",
  "Hamiltonstövare",
  "Hanover Hound",
  "Hare Indian Dog",
  "Harrier",
  "Havanese",
  "Hawaiian Poi Dog",
  "Himalayan Sheepdog",
  "Hokkaido",
  "Hovawart",
  "Huntaway",
  "Hygenhund",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Indian pariah dog",
  "Indian Spitz",
  "Irish Red and White Setter",
  "Irish Setter",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Istrian Coarse-haired Hound",
  "Istrian Shorthaired Hound",
  "Italian Greyhound",
  "Jack Russell Terrier",
  "Jagdterrier",
  "Jämthund",
  "Kai Ken",
  "Kaikadi",
  "Kanni",
  "Karelian Bear Dog",
  "Karst Shepherd",
  "Keeshond",
  "Kerry Beagle",
  "Kerry Blue Terrier",
  "King Charles Spaniel",
  "King Shepherd",
  "Kintamani",
  "Kishu",
  "Komondor",
  "Kooikerhondje",
  "Koolie",
  "Korean Jindo Dog",
  "Kromfohrländer",
  "Kumaon Mastiff",
  "Kurī",
  "Kuvasz",
  "Kyi-Leo",
  "Labrador Husky",
  "Labrador Retriever",
  "Labradoodle",
  "Lagotto Romagnolo",
  "Lakeland Terrier",
  "Lancashire Heeler",
  "Landseer",
  "Lapponian Herder",
  "Large Münsterländer",
  "Leonberger",
  "Lhasa Apso",
  "Lithuanian Hound",
  "Longhaired Whippet",
  "Löwchen",
  "Mahratta Greyhound",
  "Maltese",
  "Maltipoo (Maltese/Poodle)",
  "Manchester Terrier",
  "Maremma Sheepdog",
  "McNab",
  "Mexican Hairless Dog",
  "Miniature American Shepherd",
  "Miniature Australian Shepherd",
  "Miniature Fox Terrier",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Miniature Shar Pei",
  "Mixed",
  "Molossus",
  "Montenegrin Mountain Hound",
  "Moscow Watchdog",
  "Moscow Water Dog",
  "Mountain Cur",
  "Mucuchies",
  "Mudhol Hound",
  "Mudi",
  "Neapolitan Mastiff",
  "New Zealand Heading Dog",
  "Newfoundland",
  "Norfolk Spaniel",
  "Norfolk Terrier",
  "Norrbottenspets",
  "North Country Beagle",
  "Northern Inuit Dog",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundehund",
  "Norwich Terrier",
  "Old Croatian Sighthound",
  "Old Danish Pointer",
  "Old English Sheepdog",
  "Old English Terrier",
  "Old German Shepherd Dog",
  "Olde English Bulldogge",
  "Otterhound",
  "Pachon Navarro",
  "Paisley Terrier",
  "Pandikona",
  "Papillon",
  "Parson Russell Terrier",
  "Patterdale Terrier",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Perro de Presa Canario",
  "Perro de Presa Mallorquin",
  "Peruvian Hairless Dog",
  "Petit Basset Griffon Vendéen",
  "Petit Bleu de Gascogne",
  "Phalène",
  "Pharaoh Hound",
  "Phu Quoc ridgeback dog",
  "Picardy Spaniel",
  "Plott Hound",
  "Podenco Canario",
  "Pointer (dog breed)",
  "Polish Greyhound",
  "Polish Hound",
  "Polish Hunting Dog",
  "Polish Lowland Sheepdog",
  "Polish Tatra Sheepdog",
  "Pomeranian",
  "Pomsky (Pomeranian/Husky)",
  "Pont-Audemer Spaniel",
  "Poodle",
  "Porcelaine",
  "Portuguese Podengo",
  "Portuguese Pointer",
  "Portuguese Water Dog",
  "Pomapoo (Pomeranian/Poodle)",
  "Posavac Hound",
  "Pražský Krysařík",
  "Pudelpointer",
  "Pug",
  "Puggle (Pug/Beagle)",
  "Puli",
  "Pumi",
  "Pungsan Dog",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rafeiro do Alentejo",
  "Rajapalayam",
  "Rampur Greyhound",
  "Rastreador Brasileiro",
  "Rat Terrier",
  "Ratonero Bodeguero Andaluz",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Rough Collie",
  "Russell Terrier",
  "Russian Spaniel",
  "Russian tracker",
  "Russo-European Laika",
  "Sabueso Español",
  "Saint-Usuge Spaniel",
  "Sakhalin Husky",
  "Saluki",
  "Samoyed",
  "Sapsali",
  "Schapendoes",
  "Schillerstövare",
  "Schipperke",
  "Schweizer Laufhund",
  "Schweizerischer Niederlaufhund",
  "Scotch Collie",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Seppala Siberian Sleddog",
  "Serbian Hound",
  "Serbian Tricolour Hound",
  "Shar Pei",
  "Sheepadoodle",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Shikoku",
  "Shiloh Shepherd Dog",
  "Schnoodle (Schnauzer/Poodle)",
  "Siberian Husky",
  "Silken Windhound",
  "Sinhala Hound",
  "Skye Terrier",
  "Sloughi",
  "Slovak Cuvac",
  "Slovakian Rough-haired Pointer",
  "Small Greek Domestic Dog",
  "Small Münsterländer",
  "Smooth Collie",
  "South Russian Ovcharka",
  "Southern Hound",
  "Spanish Mastiff",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Sporting Lucas Terrier",
  "St. Bernard",
  "St. John's water dog",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Stephens Cur",
  "Styrian Coarse-haired Hound",
  "Sussex Spaniel",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Tahltan Bear Dog",
  "Taigan",
  "Talbot",
  "Tamaskan Dog",
  "Teddy Roosevelt Terrier",
  "Telomian",
  "Tenterfield Terrier",
  "Thai Bangkaew Dog",
  "Thai Ridgeback",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Tornjak",
  "Tosa",
  "Toy Bulldog",
  "Toy Fox Terrier",
  "Toy Manchester Terrier",
  "Toy Trawler Spaniel",
  "Transylvanian Hound",
  "Treeing Cur",
  "Treeing Walker Coonhound",
  "Trigg Hound",
  "Tweed Water Spaniel",
  "Tyrolean Hound",
  "Vizsla",
  "Volpino Italiano",
  "Weimaraner",
  "Welsh Sheepdog",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "West Siberian Laika",
  "Westphalian Dachsbracke",
  "Wetterhoun",
  "Wheaton Terrier",
  "Whippet",
  "White Shepherd",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Wirehaired Vizsla",
  "Yorkipoo (Yorkie/Poodle)",
  "Yorkshire Terrier",
  "Šarplaninac",
  "Other",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const CAT_BREEDS = [
  "Abyssinian",
  "Aegean",
  "American Bobtail",
  "American Curl",
  "American Shorthair",
  "American Wirehair",
  "Arabian Mau",
  "Asian",
  "Asian Semi-longhair",
  "Australian Mist",
  "Balinese",
  "Bambino",
  "Bengal",
  "Birman",
  "Bombay",
  "Brazilian Shorthair",
  "British Longhair",
  "British Semi-longhair",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "California Spangled",
  "Chantilly-Tiffany",
  "Chartreux",
  "Chausie",
  "Cheetoh",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Cyprus",
  "Devon Rex",
  "Domestic Short Hair",
  "Domestic Medium Hair",
  "Domestic Long Hair",
  "Donskoy",
  "Dragon Li",
  "Dwarf cat",
  "Egyptian Mau",
  "European Shorthair",
  "Exotic Shorthair",
  "Foldex",
  "German Rex",
  "Havana Brown",
  "Highlander",
  "Himalayan",
  "Japanese Bobtail",
  "Javanese",
  "Khao Manee",
  "Korat",
  "Korean Bobtail",
  "Korn Ja",
  "Kurilian Bobtail",
  "LaPerm",
  "Lykoi",
  "Maine Coon",
  "Manx",
  "Mekong Bobtail",
  "Minskin",
  "Munchkin",
  "Napoleon",
  "Nebelung",
  "Norwegian Forest cat",
  "Ocicat",
  "Ojos Azules",
  "Oregon Rex",
  "Oriental Bicolor",
  "Oriental Longhair",
  "Oriental Shorthair",
  "PerFold",
  "Persian (Modern)",
  "Persian (Traditional)",
  "Peterbald",
  "Pixie-bob",
  "Raas",
  "Ragamuffin",
  "Ragdoll",
  "Russian Blue",
  "Russian White, Black and Tabby",
  "Sam Sawet",
  "Savannah",
  "Scottish Fold",
  "Selkirk Rex",
  "Serengeti",
  "Serrade Petit",
  "Siamese",
  "Siberian",
  "Singapura",
  "Snowshoe",
  "Sokoke",
  "Somali",
  "Sphynx",
  "Suphalak",
  "Thai",
  "Thai Lilac",
  "Tonkinese",
  "Toyger",
  "Turkish Angora",
  "Turkish Van",
  "Ukrainian Levkoy",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const AVIAN_COLORS = [
  "Blue",
  "Blue Headed",
  "Blue/Yellow",
  "Brown",
  "Cobalt",
  "Cream/Caramel",
  "Gold/White",
  "Green",
  "Green/Brown/Red",
  "Green/Grey",
  "Green/Yellow",
  "Grey",
  "Grey/Red",
  "Lutino",
  "Multi",
  "Natural",
  "Normal Grey",
  "Orange/Yellow",
  "Peach",
  "Quaker Parrot",
  "Red",
  "Red Collared",
  "Red Pineapple",
  "Turquoise",
  "White",
  "White Hooded",
  "Yellow",
  "Yellow/Orange/Green",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const FELINE_COLORS = [
  "Amber",
  "Apricot",
  "Apricot Tabby",
  "Apricot/White",
  "Beige",
  "Beige/Black",
  "Black",
  "Black/Brown",
  "Black/Grey",
  "Black/White",
  "Black Brow",
  "Black Smoked",
  "Black Spotted",
  "Black Tabby",
  "Black Torti",
  "Black/Gold",
  "Black/Cream",
  "Black/Grey",
  "Black/Silver",
  "Black/Tan",
  "Black/Tan/Brown",
  "Black/White",
  "Black/White/Taupe",
  "Blonde",
  "Blue",
  "Blue Cream",
  "Blue Cream Calico",
  "Blue Cream Tabby",
  "Blue Point",
  "Blue Tabby",
  "Blue/White",
  "Brown",
  "Brown/Gray",
  "Brown/White",
  "Brown Mackerel",
  "Brown Point",
  "Brown Spot",
  "Brown Tabby",
  "Brown/Beige",
  "Brown/Black",
  "Brown/Cream",
  "Brown/Grey",
  "Brown/Grey/Black Stripe",
  "Brown/Orange",
  "Brown/Tan Tabby",
  "Brown/White Tabby",
  "Calico",
  "Carmel",
  "Carmel/White",
  "Champagne",
  "Charcoal",
  "Charcoal/White",
  "Chestnut",
  "Chocolate Point",
  "Chocolate/Cream",
  "Cinnamon/Silver",
  "Cream",
  "Cream/Brown",
  "Cream/Grey",
  "Dark Grey",
  "Dark Tabby",
  "Dilute Calico",
  "Dilute Tabby",
  "Dilute Torti",
  "Ebony",
  "Fawn",
  "Field Point",
  "Flame",
  "Flame Point",
  "Gold",
  "Golden",
  "Grey",
  "Grey/White",
  "Grey Point",
  "Grey Tabby",
  "Grey Tiger",
  "Grey Torti",
  "Grey White",
  "Grey/Black",
  "Grey/Black/White",
  "Grey/Brown",
  "Grey/Brown/Black",
  "Grey/Brown/White",
  "Grey/Cream",
  "Grey/Orange",
  "Grey/Silver Tabby",
  "Grey/Tan",
  "Grey/Tan/Black",
  "Grey/White",
  "Hairless",
  "Heather Tabby",
  "Leopard",
  "Light Calico",
  "Lilac",
  "Lilac Point",
  "Lynx Point",
  "Lynx/Spot",
  "Mackeral/White",
  "Mixed",
  "Mixed Grey",
  "Multi",
  "Multi Color",
  "Muted Calico",
  "Natural Mink",
  "Orange",
  "Orange/White",
  "Orange Tabby",
  "Orange Tiger",
  "Orange/Brown/Black",
  "Orange/Grey",
  "Orange/White",
  "Pastel Calico",
  "Pastel Torti",
  "Platinum",
  "Platinum Point",
  "Red",
  "Red Tabby",
  "Red/White",
  "Reddish",
  "Rose Point",
  "Ruddy",
  "Sable",
  "Seal Lynx/Mittens",
  "Seal Point",
  "Sealpoint",
  "Shaded",
  "Silver",
  "Silver Patch Mark",
  "Silver Tabby",
  "Silver/Black",
  "Silver/White",
  "Smoke",
  "Sorrel",
  "Spotted",
  "Striped",
  "Tan",
  "Tan/White",
  "Tan/Black",
  "Tan/Blk.",
  "Tan/Grey",
  "Tan/White",
  "Tiger",
  "Tiger Gray",
  "Tiger Grey",
  "Tiger Stripe",
  "Tortoise Shell",
  "Tortoiseshell",
  "Tri-color",
  "Tuxedo",
  "Unknown Color",
  "Various",
  "White",
  "White Spotted",
  "White Tabby",
  "White/Grey",
  "White/Beige",
  "White/Black",
  "White/Black/Brown",
  "White/Blue",
  "White/Brown",
  "White/BrownTabby",
  "White/Ginger",
  "White/Orange",
  "White/Tan",
  "Yellow",
  "Yellow Tabby",
  "Yellow/Grey/White",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const CANINE_COLORS = [
  "Amber",
  "Apricot",
  "Apricot/White",
  "Auburn",
  "Beige",
  "Beige/Brown",
  "Beige/Cream",
  "Beige/White",
  "Black",
  "Black/Brown",
  "Black/Gray",
  "Black/Red",
  "Black/Tan",
  "Black/White",
  "Black/Silver",
  "Black Spotted",
  "Black/Beige",
  "Black/Blonde",
  "Black/Cream",
  "Black/Gold",
  "Black/Grey",
  "Black/Grey/White",
  "Black/Mahogany",
  "Black/Red",
  "Black/Rust",
  "Black/Silver",
  "Black/Tan",
  "Black/White",
  "Black/White/Brown",
  "Black/White/Grey",
  "Black/White/Red",
  "Black/White/Tan",
  "Blond",
  "Blond",
  "Blonde",
  "Blonde/Brown",
  "Blonde/Golden",
  "Blonde/White",
  "Blue",
  "Blue/Gold",
  "Blue/Tan",
  "Blue/White",
  "Blue/Grey",
  "Blue/White",
  "Brindle",
  "Bronze",
  "Brown",
  "Brown/Grey",
  "Brown/Black",
  "Brown/blck",
  "Brown/blk",
  "Brown/Grey/Black Stripe",
  "Brown/Liver",
  "Brown/Red",
  "Brown/Tan",
  "Brown/Tan/Black",
  "Brown/White",
  "Brown/White/Tan",
  "Brown/Yellow/Black",
  "Brown/Yellow/White",
  "Calico",
  "Caramel",
  "Caramel/White",
  "Carmel",
  "Champagne",
  "Champagne",
  "Charcoal",
  "Charcoal/Black",
  "Chestnut",
  "Chestnut/Black/White",
  "Chocolate",
  "Chocolate/Charcoal",
  "Chocolate/White",
  "Cinnamon",
  "Copper",
  "Copper Brown",
  "Cream",
  "Cream/Apricot",
  "Cream/Black",
  "Cream/Brown",
  "Cream/Caramel",
  "Cream/Fawn",
  "Cream/Tan",
  "Cream/White",
  "Dark",
  "Dark Brown",
  "Fawn",
  "Fawn/Black",
  "Fawn/Red",
  "Fawn/White",
  "Gold",
  "Gold/White",
  "Gold/Black",
  "Gold/Brown",
  "Gold/White",
  "Golden",
  "Golden Amber",
  "Grey",
  "Grey/Black",
  "Grey/Black/Brown",
  "Grey/Black/White",
  "Grey/Brown",
  "Grey/Cream",
  "Grey/Gold",
  "Grey/Liver",
  "Grey/Rust",
  "Grey/Tan",
  "Grey/White",
  "Honey",
  "Ivory",
  "Light",
  "Light Brown",
  "Light Gold",
  "Light Tan",
  "Liver",
  "Liver/Brown",
  "Liver/White",
  "Liver/Wht",
  "Mahogany",
  "Maroon",
  "Mottled",
  "Multi",
  "Multi Color",
  "Orange",
  "Orange/Black",
  "Orange/Cream",
  "Orange/White",
  "Peach",
  "Pink",
  "Red",
  "Red/Black",
  "Red/Brown",
  "Red/Fawn",
  "Red/Gold",
  "Red/Rust",
  "Red/Tan",
  "Red/White",
  "Ruby",
  "Russet",
  "Rust",
  "Rust/Black",
  "Rust/Tan",
  "Rust/White",
  "Sable",
  "Sable/White",
  "Salt/Pepper",
  "Sandy",
  "Seal",
  "Seal/White",
  "Sesame",
  "Silver",
  "Silver Dapple",
  "Silver/Black",
  "Silver/Brown",
  "Silver/Grey",
  "Silver/Red",
  "Silver/Tan",
  "Smoke",
  "Spotted",
  "Sulden",
  "Tan",
  "Tan/Black",
  "Tan/Blue",
  "Tan/Brown",
  "Tan/Cream",
  "Tan/Grey",
  "Tan/White",
  "Tan/White/Rust",
  "Tawny",
  "Ticked",
  "Tri-Color",
  "Unknown Color",
  "Various",
  "Wheat",
  "Wheat/Black",
  "Wheat/Brown",
  "White",
  "White/Black",
  "White/Black/Brown",
  "White/Brown",
  "White/Copper/Black",
  "White/Cream",
  "White/Fawn",
  "White/Gold",
  "White/Grey",
  "White/Liver",
  "White/Orange",
  "White/Red",
  "White/Silver/Black",
  "White/Spot",
  "White/Tan",
  "White/Tan/Black",
  "Yellow",
  "Yellow/Red",
  "Red Merle",
  "Blue Merle",
  "Harlequin",
  "Blue brindle",
].map((b) => ({ value: b.toLowerCase(), label: b }));

export const STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District Of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];
