const API_URL =
  process.env.NODE_ENV === "production"
    ? `https://${process.env.REACT_APP_API_URL}.vetspire.com/`
    : "http://localhost:4000/";
// "http://192.168.1.6:4000/";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function buildHeaders() {
  const authToken = localStorage.getItem("authToken");

  return { ...defaultHeaders, Authorization: (authToken && authToken !== "") ? `Bearer ${authToken}` : "" };
}

function parseJSON(resp) {
  return resp.json();
}

export function apiPost(url, data, parse = true) {
  const body = JSON.stringify(data);

  const promise = fetch(API_URL + url, {
    method: "post",
    headers: buildHeaders(),
    body,
  });

  if (parse) return promise.then(parseJSON);
  return promise;
}

export function apiGet(url) {
  return fetch(API_URL + url, {
    headers: buildHeaders(),
  }).then(parseJSON);
}
