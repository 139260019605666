import React from "react";
import { css } from "emotion";
import Label from "./Label";

export const Greeting = ({ children }) => (
  <div
    className={css`
      font-weight: 700;
      font-size: 0.8em;
      margin-bottom: 5px;
      text-align: center;
      color: Black;
      width: 100%;
    `}
  >
    {children.toUpperCase()}
  </div>
);

export const Instructions = ({ children }) => (
  <div
    className={css`
      font-weight: 700;
      font-size: 0.6em;
      display: flex;
      justify-items: flex-start;
      padding: 15px;
      color: Black;
    `}
  >
    {children.toUpperCase()}
  </div>
);

export const Options = ({ id, options, value, onChange, label, active }) => (
  <div
    className={css`
      margin-bottom: 10px;
    `}
    id={id}
  >
    <Label label={label} />
    <div
      className={css`
        display: grid;
        grid-template-columns: repeat(${Math.min(4, options.length)}, 1fr);
        grid-gap: 15px;
        width: auto;
        margin-top: 8px;
        padding: 8px;
      `}
    >
      {options.map((o) => (
        <div
          key={o.value}
          className={css`
            border-radius: 5px;
            background-color: silver;
            max-width: 75px;
            color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: "700";
            font-size: 0.6em;
            padding: 5px;
            height: 30px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            ${value === o.value && {
              backgroundColor: "#282828",
              color: "#FFFFFF",
              fontWeight: "700",
            }}
          `}
          onClick={() => {
            onChange(o.value);
          }}
        >
          {o.label.toUpperCase()}
        </div>
      ))}
    </div>
  </div>
);

export const NextButton = ({ disabled, onClick, label, center }) => {
  return (
    <div
      className={css`
        height: 100%;
        display: flex;
        align-items: bottom;
        font-size: 0.7em;
      `}
    >
      <div
        className={css`
          background-color: black;
          width: 100%;
          height: 5em; /* Height of the footer */
          background: black;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          className={css`
            font-weight: 700;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              background: transparent;
              border: none;
              outline: none;
              font: inherit;
              background-color: red;
              color: rgba(255, 255, 255, 0.9);
              padding: 10px;
              min-width: 100px;
              height: auto;
              border-radius: 5px;
              font-size: 1.2em;
              text-align: center;
              font-weight: 700;
              box-shadow: 0 0 0 1px rgba(79, 178, 134, 0.1),
                0 2px 5px 0 rgba(79, 178, 134, 0.08),
                0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
                0 1px 2px 0 rgba(0, 0, 0, 0.08);
              transition: background-color 0.2s ease, opacity 0.2s ease,
                color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
            }

            button:hover {
              box-shadow: 0 1px 1px 0 rgba(79, 178, 134, 0.45),
                0 1px 3px 1px rgba(79, 178, 134, 0.45);
              cursor: pointer;
              /* background-color: #b6f3d8; */
              color: #fff;
            }
          `}
        >
          <button onClick={() => onClick()}>{label || "NEXT"}</button>
        </div>
      </div>
    </div>
  );
};
