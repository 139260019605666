import React, { Component } from "react";
import { css } from "emotion";
import { apiGet, apiPost } from "./utils/api";
import queryString from "./deps/queryString";
import moment from "moment";

import Select from "./components/Select";
import Steps from "./components/Steps";
import Header from "./containers/Header";
import Client from "./containers/Client";
import Patient from "./containers/Patient";
import ClientRdvms from "./containers/ClientRdvms";
import Confirm from "./containers/Confirm";
import ClientAddress from "./components/ClientAddress";
import DateOfBirth from "./components/DateOfBirth";
import LogoImage2 from "./images/VEG_StackedLogo_w.png";
import { Greeting, NextButton, Instructions, Options } from "./components";
import { CONFIRM_OPTIONS } from "./constants/values";
import { isDateOfBirthRequired } from "./utils/helpers";
import { STATES } from "./constants/values";

function redirectHome() {
  window.location = "https://veterinaryemergencygroup.com/";
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

class App extends Component {
  state = {};

  isDateOfBirthValid(state) {
    const dateValid = moment(state.dateOfBirth, "YYYY-MM-DD", true).isValid();
    const dobRequired = isDateOfBirthRequired(state);

    return !dobRequired || (dobRequired && dateValid);
  }

  componentDidMount() {
    const paths = window.location.pathname.split("/");

    if (paths.length !== 2 || paths[1] === "") redirectHome();

    const search = window.location.search;
    const initialState = queryString.parse(search.slice(1));

    apiGet(`self_check_in/get_checkin?appt=${initialState.appt}`)
      .then(({ data, loading }) => {
        if (!data && loading) return;
        this.setState({
          error: false,
          logoSrc: data.org.logo_src,
          orgName: data.org.name,
          provider: {
            profileImagePath: data.provider.profile_image_path,
            bio: data.provider.bio,
            name: data.provider.name,
          },
          email: data.client.email,
          clientId: data.client.id,
          patientId: data.patient.id,
          pronouns: data.client.pronouns?.toUpperCase(),
          pronounsOther: data.client.pronounsOther,
          givenName: data.client.givenName,
          familyName: data.client.familyName,
          dateOfBirth: data.client.dateOfBirth,
          clientReferralSourceId: data.client.client_referral_source_id,
          declineRdvm: data.client.declineRdvm,
          isNewRdvmNeeded: data.client.isNewRdvmNeeded,
          client_rdvms: data.client.client_rdvms,
          preferredPhoneNumber: data.client.preferred_phone_number,
          address: data.client.address,
          covidQuestion1: null,
          covidQuestion2: null,
          covidQuestion3: null,
          patientName: data.patient.name,
          patientBirthMonth: data.patient.birth_month,
          patientBirthYear: data.patient.birth_year,
          patientBirthDay: data.patient.birth_day,
          birthDate: data.patient.birth_year
            ? `${data.patient.birth_month}-${data.patient.birth_day}-${data.patient.birth_year}`
            : null,
          species: data.patient.species,
          breed: data.patient.breed,
          sex: data.patient.sex,
          color: data.patient.color,
          neutered: data.patient.neutered,
          appt: initialState.appt,
          client_referral_options: data.org.client_referral_sources,
          step: "start",
          street_number: "",
          client_primary_address: {
            street_address: data.client.primary_address
              ? data.client.primary_address.street_address
              : "",
            street_address2: data.client.primary_address
              ? data.client.primary_address.street_address2
              : "",
            city: data.client.primary_address
              ? data.client.primary_address.city
              : "",
            state: data.client.primary_address
              ? data.client.primary_address.state
              : "",
            postal_code: data.client.primary_address
              ? data.client.primary_address.postal_code
              : "",
          },
          location: {
            address: data.location.location_address,
            phoneNumber: data.location.location_phone_number,
            rdvms: data.location.rdvms,
            locationImage: data.location.clinic_image_path
          },
        });
      })
      .catch(() => this.setState({ error: true }));
  }

  saveInput(step, done) {
    window.scrollTo(0, 0);

    apiPost(`self_check_in/update_appointment_info`, {
      appt_id_token: this.state.appt,
      input: {
        clientId: this.state.clientId,
        givenName: this.state.givenName,
        familyName: this.state.familyName,
        dateOfBirth: this.state.dateOfBirth,
        pronouns:
          this.state.pronouns && this.state.pronouns !== ""
            ? this.state.pronouns.toLowerCase()
            : null,
        pronounsOther: this.state.pronounsOther,
        clientReferralSourceId: this.state.clientReferralSourceId,
        email: this.state.email,
        declineRdvm: this.state.declineRdvm,
        isNewRdvmNeeded: this.state.isNewRdvmNeeded,
        patientName: this.state.patientName,
        patientId: this.state.patientId,
        breed: this.state.breed,
        species: this.state.species,
        sex: this.state.sex ? this.state.sex.toLowerCase() : null,
        color: this.state.color,
        neutered: this.state.neutered,
        birthDate: this.state.birthDate,
        contagionRisk:
          this.state.covidQuestion1 ||
          this.state.covidQuestion2 ||
          this.state.covidQuestion3,
        preferredPhoneNumber: this.state.preferredPhoneNumber,
        client_primary_address: {
          street_address: this.state.client_primary_address.street_address,
          street_address2: this.state.client_primary_address.street_address2,
          city: this.state.client_primary_address.city,
          state: this.state.client_primary_address.state,
          postal_code: this.state.client_primary_address.postal_code,
        },
        done: done,
      },
    })
      .then((r) => {
        this.setState({ done: done });
        this.setState({ step: step });

        if (step === "done") window.scrollTo(0, 0);
      })

      .catch(() =>
        alert("An error occurred. Please start over and try again.")
      );
  }

  render() {
    const steps = [
      { label: "Client Info", value: "client" },
      { label: "Patient Details", value: "patient" },
      { label: "Referring Vet", value: "rdvm" },
      { label: "Final", value: "final" },
    ];

    return (
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 100vh;
            width: 100vw;
          `}
        >
          <div
            className={css`
              color: black;
              background-color: #f2f2f2;
              width: 100vw;
            `}
          >
            <Header
              logoImage={LogoImage2}
              address={
                this.state.location && this.state.location.address
                  ? this.state.location.address
                  : ""
              }
              phoneNumber={
                this.state.location && this.state.location.phoneNumber
                  ? this.state.location.phoneNumber
                  : ""
              }
            ></Header>

            {this.state.error && (
              <div>
                <div
                  className={css`
                    text-align: center;
                    padding: 10px;
                  `}
                >
                  <Instructions>
                    Your check-in link has expired. Please contact the office to
                    receive a new check-in link
                  </Instructions>
                </div>
              </div>
            )}

            {this.state.step && this.state.step !== "start" && (
              <div
                className={css`
                  margin-bottom: 10px;
                  width: 100vw;
                `}
              >
                <Steps
                  currentStep={this.state.step}
                  steps={steps}
                  goBackTo={(step) => this.setState({ step: step })}
                />
              </div>
            )}
            {this.state.step &&
              this.state.step === "start" &&
              !this.state.done && (
                <div>
                  <div
                    className={css`
                      margin-top: 20px;
                      background-color: #f2f2f2;
                      padding: 20px;
                      color: black;
                      font-size: 1.3em;
                      height: 100%;
                      overflow: scroll;
                    `}
                  >
                    <div>
                      <div
                        className={css`
                          text-align: center;
                          padding: 10px;
                          img {
                            max-height: 100px;
                          }
                        `}
                      >
                        <Greeting>Thank you for choosing VEG.</Greeting>
                        {this.state.provider.name && (
                          <div
                            className={css`
                              font-weight: 500;
                              font-size: 0.7em;
                              margin-top: 0.2em;
                            `}
                          >
                            {this.state.provider.name} is looking forward to
                            meeting you.
                          </div>
                        )}
                        <br></br>
                        {this.state.provider.profileImagePath && (
                          <img
                            src={this.state.provider.profileImagePath}
                            image-orientation="from-image"
                            alt="Profile"
                          ></img>
                        )}
                        {this.state.provider.bio && (
                          <div
                            className={css`
                              font-size: 0.7em;
                            `}
                          >
                            {this.state.provider.bio}
                          </div>
                        )}
                        <br></br>
                        <Greeting>
                          {this.state.patientName &&
                          this.state.patientName !== "New Patient"
                            ? `Let's get ${this.state.patientName} checked in.`
                            : "Let's get your pet checked in."}
                        </Greeting>
                      </div>
                    </div>
                  </div>

                  {this.state.provider.bio && (
                    <div
                      className={css`
                        border: 1px solid #ccc;
                        width: 100%;
                      `}
                    >
                      <NextButton
                        center
                        label="START"
                        onClick={() => {
                          this.setState({ step: "client" });
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )}
                  {!this.state.provider.bio && (
                    <div
                      className={css`
                        border: 1px solid #ccc;
                        width: 100%;
                        position: fixed;
                        bottom: 0;
                      `}
                    >
                      <NextButton
                        center
                        label="START"
                        onClick={() => {
                          this.setState({ step: "client" });
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            {this.state.step && this.state.step === "client" && (
              <div>
                <div
                  className={css`
                    height: 100%;
                  `}
                >
                  <Client
                    givenName={this.state.givenName}
                    familyName={this.state.familyName}
                    pronouns={this.state.pronouns}
                    pronounsOther={this.state.pronounsOther}
                    email={this.state.email}
                    phoneNumber={this.state.preferredPhoneNumber}
                    errorMap={this.state.errorMap}
                    covidQuestion1={this.state.covidQuestion1}
                    covidQuestion2={this.state.covidQuestion2}
                    covidQuestion3={this.state.covidQuestion3}
                    onChange={(field, value) => {
                      let v =
                        field === "preferredPhoneNumber"
                          ? value.replace(/\D/g, "")
                          : value;
                      if (
                        (field === "givenName" ||
                          field === "familyName" ||
                          field === "preferredPhoneNumber" ||
                          field === "email") &&
                        value.trim() !== ""
                      ) {
                        var errorMap = {
                          ...this.state.errorMap,
                          [field + `Error`]: null,
                        };
                        this.setState({
                          errorMap: errorMap,
                        });
                      }

                      this.setState({ [field]: v });
                    }}
                  ></Client>
                  <ClientAddress
                    onChange={(field, value) => {
                      var clientPrimaryAddress = {
                        ...this.state.client_primary_address,
                        [field]: value,
                      };

                      this.setState({
                        client_primary_address: clientPrimaryAddress,
                      });
                    }}
                    client_primary_address={this.state.client_primary_address}
                    errorMap={this.state.errorMap}
                  ></ClientAddress>
                  {isDateOfBirthRequired(this.state) && (
                    <DateOfBirth
                      dateOfBirth={this.state.dateOfBirth}
                      onChange={(value) => {
                        this.setState({
                          dateOfBirth: value,
                        });
                      }}
                      errorMap={this.state.errorMap}
                      client_primary_address={this.state.client_primary_address}
                    />
                  )}
                </div>
                <div
                  className={css`
                    height: 100%;
                    margin-top: 10px;
                  `}
                >
                  <NextButton
                    center
                    onClick={() => {
                      let validEmail = !/@/.test(this.state.email)
                        ? false
                        : true;
                      var errorMap = {};
                      if (!this.state.givenName)
                        errorMap["givenNameError"] =
                          "Owner First Name is required";

                      if (!this.state.familyName)
                        errorMap["familyNameError"] =
                          "Owner Last Name is required";

                      if (!this.state.preferredPhoneNumber)
                        errorMap["phoneError"] =
                          "Preferred phone number is required";

                      if (!this.state.client_primary_address.street_address)
                        errorMap["streetAddressError"] =
                          "Street address is required";

                      if (!this.state.client_primary_address.city)
                        errorMap["cityError"] = "City is required";

                      if (
                        !this.state.client_primary_address.state ||
                        !STATES.map(({ value }) => value).includes(
                          this.state.client_primary_address.state
                        )
                      )
                        errorMap["stateError"] = "State is required";

                      if (!this.state.client_primary_address.postal_code)
                        errorMap["postalCodeError"] = "Postal Code is required";

                      if (!validEmail)
                        errorMap["emailError"] = "Email has invalid format";

                      if (!this.isDateOfBirthValid(this.state)) {
                        errorMap["dateError"] =
                          "A valid date of birth is required";
                      }
                      this.setState({ errorMap: errorMap });

                      if (isEmpty(errorMap)) {
                        this.saveInput("patient", "false");
                        window.scrollTo(0, 0);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {this.state.step && this.state.step === "patient" && (
              <div>
                <div
                  className={css`
                    height: 100%;
                  `}
                >
                  <Patient
                    patientName={
                      this.state.patientName &&
                      this.state.patientName === "New Patient"
                        ? ""
                        : this.state.patientName
                    }
                    sex={this.state.sex}
                    species={this.state.species}
                    breed={this.state.breed}
                    birthDate={this.state.birthDate}
                    color={this.state.color}
                    neutered={this.state.neutered}
                    errorMap={this.state.errorMap}
                    onChange={(field, value) => {
                      if (
                        (field === "patientName" || field === "species") &&
                        value.trim() !== ""
                      ) {
                        var errorMap = {
                          ...this.state.errorMap,
                          [field + `Error`]: null,
                        };
                        this.setState({
                          errorMap: errorMap,
                        });
                      }

                      this.setState({ [field]: value });
                    }}
                  ></Patient>
                  <div
                    className={css`
                      height: 100%;
                      margin-top: 10px;
                    `}
                  >
                    <NextButton
                      center
                      onClick={() => {
                        var errorMap = {};

                        if (
                          !this.state.patientName ||
                          this.state.patientName === "New Patient"
                        )
                          errorMap["patientNameError"] =
                            "Patient Name is required";

                        if (
                          !this.state.species ||
                          this.state.species === "Select Species"
                        )
                          errorMap["speciesError"] = "Species is required";

                        this.setState({ errorMap: errorMap });

                        if (isEmpty(errorMap)) {
                          this.saveInput("rdvm", "false");
                        }
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.step && this.state.step === "rdvm" && (
              <div
                className={css`
                  margin-top: 15px;
                `}
              >
                <ClientRdvms
                  rdvms={this.state.location.rdvms}
                  client_rdvms={this.state.client_rdvms}
                  declineRdvm={this.state.declineRdvm}
                  isNewRdvmNeeded={this.state.isNewRdvmNeeded}
                  name="rDVM Practice Name"
                  field="rdvm"
                  appt={this.state.appt}
                  errorMap={this.state.errorMap}
                  value={this.state.selectedRdvm}
                  onChange={(field, value) => {
                    if (field === "declineRdvm")
                      this.setState({ isNewRdvmNeeded: false });
                    if (field === "isNewRdvmNeeded")
                      this.setState({ declineRdvm: false });
                    this.setState({ [field]: value });
                  }}
                  onRemove={(id) => {
                    apiPost(`self_check_in/remove_client_rdvm`, {
                      appt_id_token: this.state.appt,
                      input: {
                        client_rdvm_id: id,
                      },
                    })
                      .then((r) => {
                        let c = this.state.client_rdvms;
                        c.splice(
                          c.findIndex((item) => item.id === id),
                          1
                        );
                        this.setState({ client_rdvms: c });
                      })
                      .catch(() =>
                        alert(
                          "An error occurred. Please start over and try again."
                        )
                      );
                  }}
                  onAdd={(selected) => {
                    this.setState({ selectedRdvm: selected });
                    apiPost(`self_check_in/add_client_rdvm`, {
                      appt_id_token: this.state.appt,
                      input: {
                        client_rdvm_id: selected
                          ? selected.value.toString()
                          : "",
                      },
                    }).then((r) => {
                      let c = this.state.client_rdvms;
                      let newRdvm = {
                        id: this.state.selectedRdvm.value,
                        name: this.state.selectedRdvm.name,
                        city: this.state.selectedRdvm.city,
                        state: this.state.selectedRdvm.state,
                        address_line1: this.state.selectedRdvm.address_line1,
                        address_line2: this.state.selectedRdvm.address_line2,
                      };
                      c.push(newRdvm);
                      this.setState({
                        client_rdvms: c,
                        selectedRdvm: null,
                        declineRdvm: false,
                        isNewRdvmNeeded: false,
                      });
                    });
                  }}
                />
                <div
                  className={css`
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    margin-top: 20px;
                  `}
                >
                  <NextButton
                    center
                    onClick={() => {
                      var errorMap = {};
                      if (
                        !this.state.declineRdvm &&
                        !this.state.isNewRdvmNeeded &&
                        (!this.state.client_rdvms ||
                          isEmpty(this.state.client_rdvms))
                      )
                        errorMap["rdvmsError"] =
                          "A selection above is required";

                      this.setState({ errorMap: errorMap });

                      if (isEmpty(errorMap)) {
                        this.saveInput("final", "false");
                      }
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              </div>
            )}
            {this.state.step && this.state.step === "final" && (
              <div
                className={css`
                  padding-bottom: 35px;
                `}
              >
                <div
                  className={css`
                    margin-top: 25px;
                    font-size: 1.3em;
                  `}
                >
                  <Instructions>How did you hear about us?</Instructions>
                </div>
                <div
                  className={css`
                    background-color: #f2f2f2;
                    padding: 10px;
                    color: black;
                    font-size: 1.3em;
                    height: 100%;
                  `}
                >
                  <div
                    className={css`
                      margin-top: 5px;
                      background-color: #f2f2f2;
                      color: black;
                    `}
                  >
                    {this.state.errorMap && (
                      <div
                        className={css`
                          color: red;
                          margin-bottom: 15px;
                        `}
                      >
                        {this.state.errorMap["referralError"]}
                      </div>
                    )}

                    <Select
                      key={10}
                      options={this.state.client_referral_options}
                      value={
                        this.state.clientReferralSourceId != null
                          ? this.state.clientReferralSourceId
                          : ""
                      }
                      onChange={(value) => {
                        if (value === "Select...")
                          this.setState({ clientReferralSourceId: null });
                        else this.setState({ clientReferralSourceId: value });
                      }}
                      clearable={false}
                      placeholder="Select..."
                    />
                  </div>
                </div>
                <div
                  className={css`
                    font-size: 1.3em;
                  `}
                >
                  <Instructions>COVID-19</Instructions>
                </div>

                <div
                  className={css`
                    background-color: #f2f2f2;
                    padding: 10px;
                    color: black;
                    font-size: 1.3em;
                    height: 100%;
                  `}
                >
                  <div
                    className={css`
                      margin-top: 5px;
                      background-color: #f2f2f2;
                      color: black;
                    `}
                  >
                    {this.state.errorMap &&
                      this.state.errorMap["covidQuestionError"] && (
                        <div
                          className={css`
                            color: red;
                            margin-bottom: 15px;
                          `}
                        >
                          {this.state.errorMap["covidQuestionError"]}
                        </div>
                      )}

                    <Options
                      key={9}
                      label="Have you experienced any symptoms of COVID-19, including a fever of 100.4 degrees or higher, a new cough, new loss of taste/smell/shortness of breath, within the past 10 days?"
                      value={this.state.covidQuestion1}
                      options={CONFIRM_OPTIONS}
                      onChange={(v) => this.setState({ covidQuestion1: v })}
                    />

                    <Options
                      key={10}
                      label="Have you gotten a positive result from COVID-19 test in the past 10 days?"
                      value={this.state.covidQuestion2}
                      options={CONFIRM_OPTIONS}
                      onChange={(v) => this.setState({ covidQuestion2: v })}
                    />

                    <Options
                      key={11}
                      label="To the best of your knowledge, in the past 14 days, have you been in close contact (within 6ft for at least 10 min) with anyone who tested positive for COVID-19?"
                      value={this.state.covidQuestion3}
                      options={CONFIRM_OPTIONS}
                      onChange={(v) => this.setState({ covidQuestion3: v })}
                    />
                  </div>
                </div>

                <div
                  className={css`
                    border: 1px solid #ccc;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                  `}
                >
                  <NextButton
                    center
                    onClick={() => {
                      console.log(this.state.clientReferralSourceId);
                      var errorMap = {};
                      if (!this.state.clientReferralSourceId)
                        errorMap["referralError"] =
                          "Please select an option below.";

                      if (
                        this.state.covidQuestion1 == null ||
                        this.state.covidQuestion2 == null ||
                        this.state.covidQuestion3 == null
                      )
                        errorMap["covidQuestionError"] =
                          "Please answer all of the COVID-19 questions";

                      this.setState({ errorMap: errorMap });

                      if (isEmpty(errorMap)) {
                        this.saveInput("confirm", "false");
                        window.scrollTo(0, 0);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {this.state.step && this.state.step === "confirm" && (
              <div>
                <Confirm state={this.state}></Confirm>
                <div
                  className={css`
                    display: flex;
                    justify-content: space-around;
                    background-color: black;
                    width: 100%;
                    background: black;
                  `}
                >
                  <NextButton
                    label="Edit"
                    center
                    onClick={() => {
                      this.saveInput("client", "false");
                      window.scrollTo(0, 0);
                    }}
                  />
                  <NextButton
                    label="Confirm"
                    center
                    onClick={() => {
                      this.saveInput("none", "true");
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              </div>
            )}

            {this.state.done &&
              this.state.done === "true" &&
              this.state.step === "none" && (
                <div
                  className={css`
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin-top: 30px;
                  `}
                >
                  <div
                    className={css`
                      color: black;
                      font-weight: 700;
                      text-align: center;
                      font-size: 1.3em;
                    `}
                  >
                    {`Thank you ${this.state.givenName}.`}
                  </div>
                  <div
                    className={css`
                      color: black;
                      font-weight: 700;
                      text-align: center;
                      font-size: 1.3em;
                    `}
                  >
                    {`${this.state.patientName} is all checked in.`}
                  </div>
                  <div
                    className={css`
                      margin-top: 40px;
                    `}
                  >
                    <div
                    className={css`
                      color: black;
                      font-weight: 700;
                      text-align: center;
                      font-size: 1.3em;
                      img {
                        max-height: 200px;
                      }
                    `}
                  >
                  
                      {`We will see you soon at VEG.`}
                     
                    {this.state.location.locationImage && ( //this logic above
                      <div>
                    <br></br>
                    <img
                      src={this.state.location.locationImage}
                      image-orientation="from-image"
                      alt="Location Image"
                    ></img>
                        </div>
                        )}
                  </div>
                  <div
                    className={css`
                      margin-top: 40px;
                    `}
                  >
                    <hr
                      className={css`
                        border: none;
                        width: 75%;
                        margin: auto;
                        height: 5px;
                        background-size: 40px 40px;
                        background-color: #ec173a;
                      `}
                    ></hr>
                  </div>
                  <div
                    className={css`
                      margin-top: 40px;
                      color: black;
                      font-weight: 700;
                      text-align: center;
                      font-size: 1.3em;
                      padding: 20px;
                    `}
                  >
                    HELPING PEOPLE AND THEIR PETS WHEN THEY NEED IT MOST
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
